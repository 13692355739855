module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-modal-routing-3/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{"aria":{"labelledby":"heading","describedby":"full_description","modal":"true"},"className":"roadmap","style":{"overlay":{"backgroundColor":"rgba(40, 40, 40, 0.75)","zIndex":"101"},"content":{"position":"absolute","top":"0","left":"0","right":"0","bottom":"0","border":"none","borderRadius":"0","padding":"0"}},"onRequestClose":null}},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"nl"},{"name":"en"}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"intro","store":true,"attributes":{"boost":10}},{"name":"body","store":true,"attributes":{"boost":5}},{"name":"url","store":true}],"resolvers":{"SearchMeta":{}},"filename":"search_index.json"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"28","siteUrl":"https://www.digid.nl","matomoUrl":"https://statistiek.digid.nl","localScript":"/assets/piwik-d54gg5.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
