
import "isomorphic-fetch"
import React, { Suspense } from "react"
import { Provider } from "react-redux"
import { withSSR } from 'react-i18next';
import createStore from "./src/state/createStore"

const store = createStore({
  hideAlert: true
});

store.subscribe(() => {
  const { hideAlert } = store.getState();
  sessionStorage.setItem(`digid-hide-alert`, hideAlert);

  // Dirty hack. Somehow app classname is not changed in the component itself.
  // probably related to:
  // https://github.com/facebook/react/issues/10591
  const app = document.getElementById("App");
  if (app) {
    if (hideAlert) {
      document.getElementById("App").classList.remove("has-alert");
    } else {
      document.getElementById("App").classList.add("has-alert");
    }
  }
})

// eslint-disable-next-line react/display-name,react/prop-types
export const wrapRootElement = ({ element }) => {
  const App = React.memo(() => element)
  const ExtendedApp = withSSR()(App);

  return (
    <Provider store={store}>
      <Suspense fallback={<div />}>
        <ExtendedApp
          initialLanguage={window.initialLanguage || 'NL'}
          initialI18nStore={window.initialI18nStore || {}}
        />
      </Suspense>
    </Provider>
  )
}

export const onClientEntry = () => {
  const hideAlert = sessionStorage.getItem('digid-hide-alert') && JSON.parse(sessionStorage.getItem('digid-hide-alert'));
  if (!hideAlert) {
    store.dispatch({ type: 'SHOW_ALERT' });
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (sessionStorage.getItem('digid-hamburger-open')) {
    sessionStorage.setItem('digid-hamburger-open', false);
  }
}
