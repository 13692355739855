import { createStore as reduxCreateStore } from "redux"

const reducer = (state, action) => {
  if (action.type === `HIDE_ALERT`) {
    return Object.assign({}, state, {
      hideAlert: 1
    })
  }
  if (action.type === `SHOW_ALERT`) {
    return Object.assign({}, state, {
      hideAlert: 0
    })
  }
  return state
}
const createStore = (initialState = { hideAlert: true }) => reduxCreateStore(reducer, initialState)
export default createStore
